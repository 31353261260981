import SimpleSchema from 'simpl-schema'

export default {
  _id: {
    type: String,
    visibility: false
  },
  name: {
    label: 'Nombre',
    type: String
  },
  imageGroup: {
    label: 'Agrupador <strong style="font-size: 14px;">(64x64px)</strong>',
    type: SimpleSchema.Any,
    optional: true,
    image: true,
    width: 64,
    height: 64,
  },
  imageDayUp: {
    label: 'Activo Día <strong style="font-size: 14px;">(64x64px)</strong>',
    type: SimpleSchema.Any,
    optional: true,
    image: true,
    width: 64,
    height: 64,
  },
  imageDayDown: {
    label: 'Inactivo Día <strong style="font-size: 14px;">(64x64px)</strong>',
    type: SimpleSchema.Any,
    optional: true,
    image: true,
    width: 64,
    height: 64,
  },
  imageNightUp: {
    label: 'Activo Noche <strong style="font-size: 14px;">(64x64px)</strong>',
    type: SimpleSchema.Any,
    optional: true,
    image: true,
    width: 64,
    height: 64,
  },
  imageNightDown: {
    label: 'Inactivo Noche <strong style="font-size: 14px;">(64x64px)</strong>',
    type: SimpleSchema.Any,
    optional: true,
    image: true,
    width: 64,
    height: 64,
  },
  // attributes: {
  //   label: 'Atributos',
  //   type: Object,
  //   optional: true,
  //   attributes: true
  // }
};
